import React, { FC, MouseEventHandler, useState } from "react";
import IntroPage from "./IntroPage";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {API_URL} from "../../utils/consts";
import {Store, useStore} from "../../store";
import { motion } from 'framer-motion';

const Step3: FC<{ onNext: MouseEventHandler<HTMLButtonElement>}> = ({ onNext }) => {
  const { t } = useTranslation();
  const [isExiting, setIsExiting] = useState(false);

  const goToPage = useStore((state: Store) => state.goToPage)
  const userData = useStore((state: Store) => state.userData)
  const setUserData = useStore((state: Store) => state.setUserData)

  const handleExit = () => {
    if (userData.id !== "null") {
      axios.patch(`${API_URL}/users/${userData.id}/complete_intro`)
        .then(() => goToPage("home"))
        .catch((err) => console.log(err))
    } else {
      goToPage("home")
    }
  };

  const start = async () => {
    setIsExiting(true);
    setUserData({ ...userData, introCompleted: true });
    // Wait for exit animation to complete
    await new Promise(resolve => setTimeout(resolve, 500));
    handleExit();
  };

  return(
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: isExiting ? 0 : 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <IntroPage>
        <div className="flex flex-1 flex-col w-full content-center justify-center">
          <motion.p
            className="text-center sacramento"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            {t('intro.step3.title')}
          </motion.p>

          <motion.div
            className="flex flex-col w-full justify-center items-center relative h-[84px] my-16"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <div className="w-[84px] h-[84px] rounded-full border-white absolute top-[0px] left-[calc(50%-68px)]"
              style={{ boxShadow: "0px 0px 5px 1px #fff" }}
            />
            <div className="w-[68px] h-[68px] rounded-full border-white absolute top-[10px] left-[calc(50%-18px)]"
              style={{ boxShadow: "0px 0px 5px 1px #fff" }}
            />
          </motion.div>

          <motion.p
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.7 }}
          >
            {t('intro.step3.subtitle')}
          </motion.p>
        </div>

        <motion.div
          className="flex justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.9 }}
        >
          <button
            onClick={start}
            className="btn secondary"
            disabled={isExiting}
          >
            {t('intro.step3.buttonLabel')}
          </button>
        </motion.div>
      </IntroPage>
    </motion.div>
  )
};

export default Step3;
