import React, {FC, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Store, useStore } from "../../store";
import Language from "../../components/Language";
import IntroPage from "./IntroPage";
import Country from "../../components/Country";
import { motion } from 'framer-motion';


const Step1: FC<{ onNext: MouseEventHandler<HTMLButtonElement>}> = ({ onNext }) => {
  const { t } = useTranslation()

  const userData = useStore((state: Store) => state.userData)
  const { country } = userData;

  return(
    <IntroPage>
      <motion.div
        className="flex justify-center"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Language />
      </motion.div>

      <div className="flex flex-1 flex-col w-full content-center justify-center">
        <motion.p
          className="text-center mb-8 sacramento"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          {t('intro.step1.title')}
        </motion.p>

        <motion.p
          className="text-center mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          {t('intro.step1.subtitle')}
        </motion.p>

        <motion.div
          className="flex flex-col items-center justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.7 }}
        >
          <Country />
        </motion.div>
      </div>

      <motion.div
        className="flex justify-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.9 }}
      >
        <button onClick={onNext} disabled={!country} className="btn secondary">
          {t('intro.step1.buttonLabel')}
        </button>
      </motion.div>
    </IntroPage>
  )
};

export default Step1;
