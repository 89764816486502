import React, { FC, useEffect, useMemo, useState } from "react";
import { motion } from 'framer-motion';

const animationDuration = 7;
const amount = 100;
const particleRadiusMultiplier = 200;
const particlesIndexes = Array.from({ length: amount }, (_, index) => index)

const Animation: FC<{delay?: number}> = ({ delay = 0 }) => {
  const [started, setStarted] = useState(false);
  const [expanding, setExpanding] = useState(true);

  useEffect(() =>{
    let timeout = setTimeout(() => { setStarted(true) }, delay * 1000);

    return () => clearTimeout(timeout);
  }, [delay]);

  useEffect(() => {
    if(started) {
      const interval = setInterval(() => {
        setExpanding(prev => !prev);
      }, animationDuration * 1000);

      return () => clearInterval(interval);
    }
  }, [started]);

  return (
    <div className="relative w-[200px] h-[200px]">
      <motion.div
        className="particle"
        initial={{
          x: "-50%",
          y: "-50%",
          scale: 1,
        }}
        animate={{
          scale: expanding ? 0.2 : 1,
        }}
        transition={{
          duration: animationDuration,
          ease: "easeInOut",
        }}
        style={{
          width: 14,
          height: 14,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          boxShadow: "rgba(255, 255, 255, 0.8) 0px 0px 8px 0px"
        }}
      />
      {particlesIndexes.map(index => (
        <Particle key={index} started={started} expanding={expanding} />
      ))}
    </div>
  );
}

const Particle: FC<{ started: boolean, expanding: boolean }> = ({ started, expanding }) => {
  const duration = useMemo(() => animationDuration - Math.floor(Math.random() * 1.5), []);
  const size = useMemo(() => Math.random() * 8 + 2, []);
  const alpha = useMemo(() => Math.random() * 0.8 + 0.1, []);
  const delay = useMemo(() => alpha > 0.5 ? 0 : Math.floor(Math.random() * (duration - 4)) + 1, [duration, alpha]);
  const initialOpacity = useMemo(() => delay > 0 ? 0 : 0.1, [delay]);

  const [x, setX] = useState<string | number>("-50%");
  const [y, setY] = useState<string | number>("-50%");
  const [opacity, setOpacity] = useState(initialOpacity);

  useEffect(() => {
    if (!started) {
      return;
    }

    if(expanding) {
      const t = 2 * Math.PI * Math.random();
      const r = Math.sqrt(Math.random());

      setX(r * particleRadiusMultiplier * Math.cos(t));
      setY(r * particleRadiusMultiplier * Math.sin(t));
      setOpacity(1);
    } else {
      setX("-50%");
      setY("-50%");
      setOpacity(initialOpacity);
    }
  }, [started, expanding, initialOpacity]);

  return (
    <motion.div
      className="particle"
      initial={{
        x: "-50%",
        y: "-50%",
        opacity: initialOpacity,
      }}
      animate={{ x, y, opacity }}
      transition={{
        duration: duration,
        ease: "easeInOut",
        opacity: { delay: expanding ? delay : 0, duration: duration - delay, ease: "easeInOut" },
      }}
      style={{
        width: size,
        height: size,
        backgroundColor: `rgba(255, 255, 255, ${alpha})`,
        boxShadow: `rgba(255, 255, 255, ${alpha}) 0px 0px 8px 0px`
      }}
    />
  );
}

export default Animation;