import React, { FC, useMemo, useState, } from "react";
import axios from "axios";
import { isNull } from "lodash";
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { API_URL } from "../../utils/consts";
import {Store, useStore} from "../../store";
import AudioControls from "./audioControls";
import Animation from "./animation";

const Meditation: FC= () => {
  const { t } = useTranslation();

  const goToPage = useStore((state: Store) => state.goToPage)
  const appData = useStore((state: Store) => state.appData)
  const userData = useStore((state: Store) => state.userData)
  const setAppData = useStore((state: Store) => state.setAppData)
  const setUserData = useStore((state: Store) => state.setUserData)

  const [isFinishing, setIsFinishing] = useState(false);

  const intention = useMemo(
    () => userData.userIntentions.find(ui => isNull(ui.endTime)),
    [userData.userIntentions]
  );

  const finish = async () => {
    // Start fade out
    setIsFinishing(true);

    // Wait for fade out to complete
    await new Promise(resolve => setTimeout(resolve, 500));

    if (!intention || userData.id === "null") {
      setUserData({ ...userData, userIntentions: [{ ...userData.userIntentions[0], endTime: new Date() }] })
      await handleExit(0)
      return
    }

    axios.patch(`${API_URL}/user_intentions/${intention.id}/finish`, { user_id: userData.id })
      .then(response => {
        setAppData({ ...appData, activeUsers: appData.activeUsers - 1 })
        const finishedIntention = response.data;
        const intentionIndex = userData.userIntentions.findIndex(i => i.id === finishedIntention.id)
        const userIntentions = [userData.userIntentions.slice(0, intentionIndex), finishedIntention, userData.userIntentions.slice(intentionIndex + 1)];
        setUserData({ ...userData, userIntentions })
        handleExit(intention.id)
      })
      .catch(err => console.log(err));
  }

  const handleExit = async (userIntentionId: number) => {
    goToPage("summary", userIntentionId)
  }

  return(
    <div>
      <div className="page h-full justify-center">
        <motion.div
          className="flex flex-col justify-center items-center w-full"
          initial={{ opacity: 0, y: -60 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -60 }}
          transition={{ duration: 0.8, delay: 0, ease: "easeOut"  }}
        >
          {appData.intention && (
            <>
              <p className="text-sm mt-12 uppercase secondary">
                {t('home.intentionHeader')}
              </p>

              <p className="my-12 light text-2xl" style={{ textShadow: "0px 0px 10px white"}}>
                {appData.intention.text}
              </p>
            </>
          )}
        </motion.div>

        <div className="flex flex-col flex-1 justify-center items-center">
          <motion.div
            className="w-full z-10"
            initial={{ opacity: 0, y: -60 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -60 }}
            transition={{ duration: 0.8, delay: 0, ease: "easeOut"  }}
          >
            <AudioControls onFinishing={isFinishing} />
          </motion.div>

          <motion.div
            className="flex flex-1 justify-center items-center"
            initial={{ opacity: 0, scale: 0.1 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.8, delay: 0.0, ease: "easeOut" } }}
            transition={{ duration: 1, delay: 0.3, ease: "easeOut" }}
          >
            <Animation delay={3}/>
          </motion.div>
        </div>

        <motion.button
          onClick={finish}
          className="btn primary mb-4 z-10"
          whileTap={{ scale: 0.95 }}
          disabled={isFinishing}
          initial={{ opacity: 0, y: 60 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 60 }}
          transition={{ duration: 0.8, delay: 0, ease: "easeOut"  }}
        >
          {t('meditation.buttonLabel')}
        </motion.button>
      </div>
    </div>
  );
}

export default Meditation;
