import React, {FC, MouseEventHandler, useState} from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { AnimatePresence } from 'framer-motion';

const Intro: FC = () => {
  const [step, setStep] = useState(0)

  const onNextClick: MouseEventHandler<HTMLButtonElement> = () => setStep(prev => prev + 1);

  return(
    <AnimatePresence mode="wait">
      {step === 0 && <Step1 key="step1" onNext={onNextClick} />}
      {step === 1 && <Step2 key="step2" onNext={onNextClick} />}
      {step === 2 && <Step3 key="step3" onNext={onNextClick} />}
    </AnimatePresence>
  )
};

export default Intro;
