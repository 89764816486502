import React, {FC, useState} from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from 'framer-motion';
import { Dialog, DialogBackdrop } from "@headlessui/react";
import { CiUser } from "react-icons/ci";
import { RiCloseFill } from "react-icons/ri";
import Language  from "./Language";
import Country from "./Country";

const Settings: FC = () => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false);

  return(
    <>
      <div className="absolute top-2 right-2 md:top-4 md:right-4">
        <button aria-label={t('settings.userProfileSetting')} className="btn circle" onClick={() => setOpen(true)}>
          <CiUser size={28} />
        </button>
      </div>

      <AnimatePresence>
        {open && <Dialog
          key="settingsDialog"
          static
          as={motion.div}
          open={open}
          onClose={() => setOpen(false)}
          className="relative z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.7, ease: "easeIn" } }}
          exit={{ opacity: 0, transition: { duration: 0.7, delay: 0.5, ease: "easeIn" } }}
        >
          <DialogBackdrop className="fixed inset-0 background-app" />
          <div className="flex flex-col fixed justify-center items-center inset-0 m-2 md:m-4">
            <button aria-label={t('settings.close')} onClick={() => setOpen(false)} className="btn circle self-end">
              <RiCloseFill size={24} />
            </button>

            <div className="flex-1 flex flex-col items-center justify-center">
              <motion.p
                className="text-sm uppercase secondary"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20, transition: { delay: 0, duration: 0.5 , ease: "easeIn" } }}
                transition={{
                  delay: 0.3,
                  duration: 0.5,
                  ease: "easeIn"
                }}
              >
                Settings
              </motion.p>

              <motion.div
                className="flex flex-col justify-center items-center mt-16"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20, transition: { delay: 0.4, ease: "easeIn" } }}
                transition={{ delay: 0.6, duration: 0.5 }}
              >
                <Language fullWidth withLabel />
              </motion.div>

              <motion.div
                className="flex flex-col justify-center items-center mt-16"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20, transition: { delay: 0.2, ease: "easeIn" } }}
                transition={{ delay: 0.8, duration: 0.5 }}
              >
                <Country />
              </motion.div>
            </div>

            <div className="flex justify-center">
              <motion.button
                className="btn secondary mb-4"
                onClick={() => setOpen(false)}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20, transition: { delay: 0, ease: "easeIn" } }}
                transition={{ delay: 0.3, duration: 0.5 }}
              >
                {t('settings.buttonLabel')}
              </motion.button>
            </div>
          </div>
        </Dialog>}
      </AnimatePresence>
    </>
  )
};

export default Settings;