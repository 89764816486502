import React, { useEffect } from 'react';
import { isNull } from "lodash";
import './App.css';
import './i18n/config';
import ErrorBoundary from "./utils/ErrorBoundary";
import { Store, useStore } from "./store";
import Home from "./pages/home";
import Intro from "./pages/intro";
import Summary from "./pages/summary";
import Meditation from "./pages/meditation";
import Splash from "./pages/Splash";

function App() {
  const page = useStore((state: Store) => state.page)
  const goToPage = useStore((state: Store) => state.goToPage)
  const userData = useStore((state: Store) => state.userData)

  useEffect(() => {
    if(userData.loaded) {
      if (!userData.introCompleted) {
        goToPage("intro")
        return;
      }

      if (page.name === undefined) {
        const intentionInProgress = userData.userIntentions.find(ui => isNull(ui.endTime))
        if (intentionInProgress) {
          goToPage("meditation")
        } else {
          goToPage("home")
        }
      }
    }

    return;
  }, [goToPage, page?.name, userData.loaded, userData.introCompleted, userData.userIntentions])

  return (
    <div className="app">
      <ErrorBoundary>
          <Splash>
            {page.name === "intro" && <Intro key="intro" />}
            {page.name === "home" && <Home key="home" />}
            {page.name === "meditation" && <Meditation key="meditation" />}
            {page.name === "summary" && <Summary key="summary" />}
          </Splash>
      </ErrorBoundary>
    </div>
  );
}

export default App;
