import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import axios from "axios";
import { motion, AnimatePresence } from 'framer-motion';
import { Store, useStore } from "../store";
import {API_URL, ENVIRONMENT} from "../utils/consts";
import { getOrInitializeUser } from "../utils/user-manager";
import '../i18n/config';
import Logo from "../assets/logo.svg";

const Splash: FC<PropsWithChildren> = ({ children }) => {
  const demo = window.location.search.includes("demo=true")

  const setAppData = useStore((state: Store) => state.setAppData)
  const setUserData = useStore((state: Store) => state.setUserData)
  const setAppDataLoaded = useStore((state: Store) => state.setAppDataLoaded)
  const userData = useStore((state: Store) => state.userData)
  const appData = useStore((state: Store) => state.appData)
  const { id: userId } = userData;

  const [waited, setWaited] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const counting = () => {
      if(counter > 0 && !waited) {
        setWaited(true);
      }
      setCounter(prev => (prev + 1) % 4);
    }
    const intervalId = setInterval(counting, 1500)

    return () => clearInterval(intervalId);
  }, [waited, counter])

  useEffect(() => {
    if (ENVIRONMENT === 'development' || demo) {
      if (!userId) {
        getOrInitializeUser().then(user => setUserData({ ...user, loaded: true }));
      }

      setAppDataLoaded(false);

      axios.get(`${API_URL}/data`).then(response => {
        setAppData({ ...response.data })
      }).catch(e => {
        console.log(e)
      }).finally(() =>{
        setAppDataLoaded(true);
      })
    }
  }, [userId, demo, setAppDataLoaded, setAppData, setUserData])

  if(waited && userData.loaded && appData.loaded) {
    return <AnimatePresence mode="wait">{children}</AnimatePresence>
  }

  return(
    <AnimatePresence mode="wait">
      <motion.div
        className="page justify-center text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.img
          className="mb-4 text-white h-[72px]"
          src={Logo}
          alt="Oraya logo"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        />

        <motion.p
          className="text-xs text-[rgba(255,255,255,0.6)]"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          Unite in Reflection, Purpose, and Hope
        </motion.p>

        <motion.p
          className="font-light text-sm mt-16 p-2 text-[rgba(255,255,255,0.6)]"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.7 }}
        >
          COMING SOON...
        </motion.p>
      </motion.div>
    </AnimatePresence>
  )
}

export default Splash;
