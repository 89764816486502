import React, { FC, PropsWithChildren } from "react";
import { motion } from 'framer-motion';

const IntroPage: FC<PropsWithChildren> = ({ children }) => (
  <motion.div
    className="flex flex-col h-full justify-center items-center p-4"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    <motion.div
      className="flex flex-col h-full justify-center items-center py-4 sm:w-[400px]"
    >
      {children}
    </motion.div>
  </motion.div>
);

export default IntroPage;
