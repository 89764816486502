import { Feature } from "geojson";
import React, {useMemo} from "react";
import * as d3 from 'd3';
import { geoData, MapNumDataType, countriesNumData } from "../utils/geo";
import {Store, useStore} from "../store";

const OPACITY_MIN_SIZE = 0.2;
const OPACITY_MAX_SIZE = 0.8;

type MapProps = {
  width: number;
  height: number;
  userCountryCode?: string;
  isJoining: boolean;
};

const Map = ({ width, height, userCountryCode, isJoining }: MapProps) => {
  const appData = useStore((state: Store) => state.appData)
  const stringifiedActiveUsersPerCountry = JSON.stringify(appData.activeUsersPerCountry);

  const countryOpacity = useMemo(
    () => {
      const newNumData = JSON.parse(JSON.stringify(countriesNumData)) as MapNumDataType[];
      JSON.parse(stringifiedActiveUsersPerCountry).forEach((countryActiveUsers: { country: string, activeUsers: number}) => {
        const element = newNumData.find((d) => d.name === countryActiveUsers.country)
        if (element) {
          element.value = Number(countryActiveUsers.activeUsers)
        }
      })

      const [min, max] = d3.extent(newNumData.map((d) => d.value)) as [number, number];

      const opacityScale = d3
        .scaleSqrt()
        .domain([min, max])
        .range([OPACITY_MIN_SIZE, OPACITY_MAX_SIZE]);

      const newCountryOpacity = {} as { [key: string]: number };
      newNumData.forEach(region => {
        const regionGeoData = geoData.features.find(
          (geoRegion: any) => geoRegion.id === region.code
        );
        if (regionGeoData && regionGeoData.id && region.value > 0) {
          newCountryOpacity[String(regionGeoData.id)] = opacityScale(region.value);
        }
      })

      return newCountryOpacity
    },
    [stringifiedActiveUsersPerCountry]
  )

  const projection = useMemo(() => d3
    .geoMercator()
    .scale(width / 2.3 / Math.PI)
    .center([0, 45])
    .translate([width/2, height/2]),
  [width, height]);

  const geoPathGenerator = d3.geoPath().projection(projection);

  const allCountryPaths = geoData.features
    .filter((shape: any) => shape.id !== 'ATA')
    .map((shape: any) => {
      return (
        <path
          key={shape.id}
          className="transition-[fill-opacity] duration-1000 delay-500"
          d={geoPathGenerator(shape) || undefined}
          fill="white"
          // fillOpacity={countryOpacity[String(shape.id)] || 0.1}
          fillOpacity={0.1}
        />
      );
    });

  const userLocationCoordinates = useMemo<[number, number] | null>(() => {
    const userRegionGeoData = geoData.features.find((geoRegion: any) =>
      geoRegion.properties.name === userCountryCode
    ) as Feature;

    let totalArea = 0;
    let xWeightedSum = 0;
    let yWeightedSum = 0;

    // Loop through geometries in case there are multiple polygons
    if (userRegionGeoData && (userRegionGeoData.geometry.type === "Polygon" || userRegionGeoData.geometry.type === "MultiPolygon")) {
      const coordinates = userRegionGeoData.geometry.type === "Polygon"
        ? [userRegionGeoData.geometry.coordinates]
        : userRegionGeoData.geometry.coordinates;

      coordinates.forEach(polygon => {
        // Wrap the polygon in a GeoJSON feature for calculations
        const subFeature = {
          type: "Feature",
          geometry: { type: "Polygon", coordinates: polygon }
        } as Feature;

        // Compute area and centroid for each polygon
        const area = d3.geoArea(subFeature);
        const centroid = d3.geoCentroid(subFeature);

        totalArea += area;
        xWeightedSum += centroid[0] * area;
        yWeightedSum += centroid[1] * area;
      });

      // Final weighted centroid
      return projection([xWeightedSum / totalArea, yWeightedSum / totalArea]);
    }

    return null;
  }, [projection, userCountryCode])

  return (
    <svg width={width} height={height}>
      <defs>
        <radialGradient id="myGradient">
          <stop offset="30%" stopColor="white" />
          <stop offset="100%" stopColor="white" stopOpacity={0} />
        </radialGradient>
      </defs>
      {allCountryPaths}
      {/*{allCircleShapes}*/}
      { userLocationCoordinates && (
        <circle
          cx={userLocationCoordinates[0]}
          cy={userLocationCoordinates[1]}
          r={isJoining ? 10 : 0}
          fillOpacity={isJoining ? 0.7 : 0}
          fill="#ffffff"
          filter="drop-shadow(0px 0px 4px #fff)"
          className="transition-[all] duration-[2000ms]"
        />
      )}
    </svg>
  );
};

export default Map;