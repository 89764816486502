import React, { FC, MouseEventHandler } from "react";
import IntroPage from "./IntroPage";
import { useTranslation } from "react-i18next";
import Infinity from "../../components/shapes/Infinity"
import { motion } from 'framer-motion';

const Step2: FC<{ onNext: MouseEventHandler<HTMLButtonElement>}> = ({ onNext }) => {
  const { t } = useTranslation()

  return(
    <IntroPage>
      <div className="flex flex-1 flex-col w-full content-center justify-center">
        <motion.p
          className="text-center mb-8 sacramento"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {t('intro.step2.title')}
        </motion.p>

        <motion.div
          className="text-center"
          style={{ alignSelf: 'center' }}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <Infinity height="100px" className="pathShadow mb-8 text-center" />
        </motion.div>

        <motion.p
          className="text-center mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          {t('intro.step2.subtitle')}
        </motion.p>

        <motion.p
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          {t('intro.step2.subtitle2')}
        </motion.p>
      </div>

      <motion.div
        className="flex justify-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <button onClick={onNext} className="btn secondary">
          {t('intro.step2.buttonLabel')}
        </button>
      </motion.div>
    </IntroPage>
  )
};

export default Step2;
